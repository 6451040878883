<template>
  <b-icon icon="telegram" :title="text || 'Telegram'"></b-icon>
</template>

<script>
import BaseIconWrapper from '../BaseIconWrapper'

export default {
  extends: BaseIconWrapper
}
</script>
