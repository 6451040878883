<template>
  <span :title="text || 'Cross-border project'">
    <svg viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path id="Vector (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
              d="M3.3499 6.00281C3.19828 5.95424 3.03724 5.94258 2.8802 5.96879L0.721482 6.3288L0.611816 5.67121L2.77043 5.31122C3.03216 5.26754 3.30058 5.28697 3.55327 5.36792C3.80599 5.44887 4.03572 5.58899 4.22336 5.77663C4.411 5.96427 4.55112 6.194 4.63207 6.44671C4.71302 6.69943 4.73246 6.96782 4.68877 7.22956L4.59883 7.76719C4.53273 8.16616 4.59608 8.57602 4.77966 8.93636C4.96325 9.2967 5.2574 9.58879 5.61903 9.76985C6.02665 9.97339 6.35256 10.31 6.54282 10.724C6.73309 11.138 6.7763 11.6045 6.6653 12.0464L6.32336 13.4142L5.6766 13.2525L6.0186 11.8845C6.09263 11.5899 6.06393 11.2784 5.93707 11.0024C5.81021 10.7264 5.5929 10.5019 5.3211 10.3662C4.83204 10.1214 4.43392 9.7263 4.18565 9.239C3.93738 8.75169 3.85169 8.19769 3.94114 7.65815L4.0312 7.11979C4.05741 6.96275 4.04575 6.80171 3.99718 6.65009C3.94861 6.49846 3.86454 6.36062 3.75195 6.24804C3.63937 6.13545 3.50153 6.05138 3.3499 6.00281Z"/>
        <path id="Vector (Stroke) (Stroke)" fill-rule="evenodd" clip-rule="evenodd"
              d="M2.93511 6.2976L0.447602 6.71244L0.228271 5.39727L2.71564 4.98245C3.02969 4.93005 3.35181 4.95336 3.65504 5.05049C3.9583 5.14763 4.23397 5.31578 4.45914 5.54095C4.68431 5.76611 4.85245 6.04179 4.94959 6.34504L4.63215 6.44673C4.5512 6.19402 4.41108 5.96429 4.22344 5.77665C4.0358 5.58901 3.80607 5.44889 3.55335 5.36794C3.30066 5.28699 3.03224 5.26756 2.77051 5.31124L0.611897 5.67123L0.721562 6.32881L2.88028 5.96881C3.03732 5.94259 3.19836 5.95425 3.34998 6.00283C3.50161 6.0514 3.63945 6.13547 3.75203 6.24805C3.86462 6.36064 3.94869 6.49847 3.99726 6.6501C4.04583 6.80173 4.05749 6.96276 4.03128 7.11981L3.94122 7.65817C3.85177 8.19771 3.93746 8.75171 4.18573 9.23901C4.434 9.72631 4.83212 10.1215 5.32118 10.3663C5.59298 10.5019 5.81029 10.7264 5.93715 11.0024C6.06401 11.2784 6.09271 11.5899 6.01868 11.8845L5.67668 13.2525L6.32344 13.4142L6.66538 12.0465C6.77638 11.6046 6.73317 11.138 6.5429 10.724C6.35264 10.31 6.02673 9.9734 5.61911 9.76987C5.25748 9.58881 4.96333 9.29672 4.77974 8.93637C4.59616 8.57603 4.53281 8.16618 4.59891 7.76721L4.68885 7.22957C4.73254 6.96783 4.7131 6.69944 4.63215 6.44673L4.94959 6.34504C5.04673 6.6483 5.07006 6.97037 5.01763 7.28445L4.92776 7.82169C4.87336 8.15037 4.92554 8.48827 5.07675 8.78505C5.22799 9.08191 5.4703 9.32255 5.76819 9.47173C6.24367 9.7092 6.62383 10.1019 6.84578 10.5848C7.06771 11.0677 7.11815 11.6119 6.98876 12.1273M6.98876 12.1273L6.56598 13.8184L5.27246 13.495L5.6953 11.8037C5.75085 11.5826 5.72937 11.3485 5.63427 11.1416C5.53913 10.9346 5.37614 10.7663 5.17229 10.6645C4.6196 10.3878 4.16934 9.94113 3.88872 9.39033C3.60812 8.83958 3.51128 8.21344 3.61237 7.60365L3.70249 7.06492C3.71995 6.96026 3.71218 6.85284 3.67982 6.75179C3.64744 6.6507 3.59139 6.55881 3.51633 6.48375C3.44127 6.4087 3.34938 6.35265 3.2483 6.32027C3.14721 6.28789 3.03981 6.28012 2.93511 6.2976"/>
        <path id="Vector (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd"
              d="M10.822 3.46661L11.8 2.73328L12.1999 3.26665L11.222 3.99996C10.9335 4.21633 10.5826 4.3333 10.222 4.3333H7.98344C7.83528 4.33338 7.69064 4.37926 7.56956 4.46464C7.44844 4.55005 7.35669 4.67081 7.30687 4.81039C7.25706 4.94997 7.25161 5.10153 7.29128 5.24433C7.33094 5.38708 7.41374 5.51408 7.52836 5.60796L8.86169 6.69862C9.42114 7.15634 9.85723 7.74675 10.1302 8.41606C10.4032 9.08537 10.5044 9.81233 10.4246 10.5308L10.4113 10.6508C10.3549 11.158 10.2303 11.6552 10.0408 12.1291L9.64279 13.1238L9.02383 12.8761L9.42181 11.8815C9.58899 11.4635 9.69896 11.0247 9.74868 10.5772L9.76201 10.4572C9.82951 9.84929 9.74388 9.23416 9.5129 8.66782C9.28193 8.10148 8.91295 7.60192 8.43957 7.21462L7.10626 6.12397C6.88521 5.943 6.7254 5.69803 6.64894 5.42277C6.57247 5.14752 6.58297 4.85535 6.679 4.58629C6.77503 4.31723 6.95189 4.08445 7.18536 3.91981C7.41884 3.75517 7.6975 3.66674 7.98318 3.66663H10.222C10.4383 3.66663 10.6489 3.59643 10.822 3.46661Z"/>
        <path id="Vector (Stroke) (Stroke)_2" fill-rule="evenodd" clip-rule="evenodd"
              d="M11.8668 2.26663L12.6667 3.33339L11.4221 4.26665C11.0759 4.52628 10.6548 4.66665 10.2221 4.66665H7.98371C7.90421 4.66669 7.82661 4.69132 7.76174 4.73707C7.6968 4.78286 7.6476 4.84761 7.62089 4.92245C7.59418 4.99729 7.59126 5.07856 7.61253 5.15512C7.63379 5.23164 7.67817 5.29972 7.7396 5.35005L9.07282 6.44063C9.67531 6.93355 10.145 7.5694 10.4389 8.2902C10.7329 9.01099 10.8419 9.79387 10.756 10.5676L10.7426 10.6876C10.683 11.2246 10.551 11.7511 10.3504 12.2529L9.93156 13.2996L9.66683 13.3333L9.42189 13.3944L8.5906 13.0618L9.11238 11.7578C9.26841 11.3676 9.37106 10.958 9.41747 10.5404L9.43079 10.4204C9.49215 9.86779 9.4143 9.30856 9.20433 8.79372C8.99435 8.27886 8.65892 7.82472 8.22857 7.47263L6.89529 6.382C6.62108 6.1575 6.42272 5.85353 6.32785 5.51201C6.23298 5.17052 6.24601 4.80806 6.36515 4.47426C6.48428 4.14046 6.7037 3.85166 6.99335 3.64741C7.283 3.44316 7.62871 3.33345 7.98313 3.33331H10.2221C10.3663 3.33331 10.5067 3.28651 10.6221 3.19996L11.8668 2.26663ZM8.43965 7.21464C8.91303 7.60193 9.28201 8.1015 9.51298 8.66784C9.74396 9.23417 9.82959 9.84931 9.76209 10.4572L9.74876 10.5772C9.69904 11.0247 9.58907 11.4635 9.42189 11.8815L9.02391 12.8761L9.64287 13.1238L10.0409 12.1291C10.2304 11.6552 10.355 11.158 10.4114 10.6508L10.4247 10.5308C10.5045 9.81235 10.4033 9.08539 10.1303 8.41608C9.85731 7.74677 9.42122 7.15635 8.86177 6.69864L7.52844 5.60797C7.41382 5.5141 7.33102 5.38709 7.29136 5.24434C7.25169 5.10155 7.25714 4.94998 7.30696 4.8104C7.35677 4.67083 7.44852 4.55006 7.56964 4.46465C7.69072 4.37927 7.83536 4.3334 7.98352 4.33331H10.2221C10.5827 4.33331 10.9336 4.21635 11.2221 3.99998L12.2 3.26667L11.8001 2.73329L10.8221 3.46662C10.649 3.59645 10.4384 3.66665 10.2221 3.66665H7.98326C7.69758 3.66676 7.41892 3.75519 7.18545 3.91983C6.95198 4.08446 6.77511 4.31725 6.67908 4.58631C6.58305 4.85536 6.57255 5.14753 6.64902 5.42279C6.72549 5.69805 6.88529 5.94302 7.10634 6.12399L8.43965 7.21464Z"/>
        <path id="Vector (Stroke)_3" fill-rule="evenodd" clip-rule="evenodd"
              d="M6.99992 1.00001C3.68621 1.00001 0.999919 3.6863 0.999919 7.00001C0.999919 10.3137 3.68621 13 6.99992 13C10.3136 13 12.9999 10.3137 12.9999 7.00001C12.9999 3.6863 10.3136 1.00001 6.99992 1.00001ZM0.333252 7.00001C0.333252 3.31811 3.31802 0.333344 6.99992 0.333344C10.6818 0.333344 13.6666 3.31811 13.6666 7.00001C13.6666 10.6819 10.6818 13.6667 6.99992 13.6667C3.31802 13.6667 0.333252 10.6819 0.333252 7.00001Z"/>
        <path id="Vector (Stroke) (Stroke)_3" fill-rule="evenodd" clip-rule="evenodd"
              d="M7 1.33333C3.87039 1.33333 1.33333 3.87039 1.33333 7C1.33333 10.1296 3.87039 12.6667 7 12.6667C10.1296 12.6667 12.6667 10.1296 12.6667 7C12.6667 3.87039 10.1296 1.33333 7 1.33333ZM0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 10.866 10.866 14 7 14C3.13401 14 0 10.866 0 7ZM1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 10.3137 10.3137 13 7 13C3.68629 13 1 10.3137 1 7ZM7 0.333333C3.3181 0.333333 0.333333 3.3181 0.333333 7C0.333333 10.6819 3.3181 13.6667 7 13.6667C10.6819 13.6667 13.6667 10.6819 13.6667 7C13.6667 3.3181 10.6819 0.333333 7 0.333333Z"/>
      </g>
    </svg>
  </span>
</template>

<script>
import BaseIconWrapper from '../BaseIconWrapper'

export default {
  extends: BaseIconWrapper
}
</script>
